import { Typography } from "@mui/material";
import { Fragment, PropsWithChildren } from "react";

type ModalViewProps = PropsWithChildren & {
  title: string;
};
export const ModalView = ({ title, children }: ModalViewProps) => (
  <Fragment>
    <Typography variant="h2" textAlign="center">
      {title}
    </Typography>
    {children}
  </Fragment>
);
